<template>
  <div class="px-0">
    <div class="add-different-discount-box row m-0 mt-3">
      <div class="py-0 pl-0 shipping-pr">
        <PSelect
          style="border-radius: 4px !important"
          :placeholder="'Select Country'"
          :options="listOfCountries"
          @change="setCountry($event)"
        />
      </div>
      <div class="py-0 pl-0">
        <PTextField
          label=""
          suffix="%"
          id="discount_percentage"
          type="number"
          class="filed-title"
          v-model="newDiscount.percentage"
        />
      </div>

      <button
        class="delete_btn Polaris-Button Polaris-Button--monochrome"
        type="button"
        @click="$emit('removeshipto')"
      >
        <PIcon source="DeleteMinor" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    listOfCountries() {
      const listOfCountries = this.countries.map((country) => {
        return { label: country.name, value: country.id };
      });
      return listOfCountries;
    },
  },
  data() {
    return {
      newDiscount: { country: "", percentage: "" },
    };
  },
  watch: {
    newDiscount: {
      handler(newDiscount) {
        this.$emit("newDiscountChange", newDiscount);
      },
      deep: true,
    },
  },
  methods: {
    setCountry(countryID) {
      const selectedCountry = this.countries.find(
        (country) => country.id === countryID
      );
      if (selectedCountry) {
        this.newDiscount.country = selectedCountry.name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-different-discount-box {
  display: grid;
  grid-template-columns: 45% 45% 70px;
}
.shipping-pr {
  padding-right: 12px;
}
.delete_btn {
  background: #f2f4f7;
  position: relative;
  color: #5c5f62;
  margin: 0;
  border-radius: 5px;
  margin-left: 14px;
  width: 70px;
  border: unset !important;
  @include lg {
    margin-left: 0 !important;
  }

  button {
    border: unset !important;
    box-shadow: unset !important;
  }
  // top: 20px;
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    top: 0;
  }
  @media screen and (min-width: 529px) and (max-width: 575px) {
    top: 7px;
  }
}
</style>
