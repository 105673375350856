var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"catalog-management"},[(!_vm.loading && _vm.renderComponent)?_c('div',{staticClass:"is-full-screen"},[_c('div',{},[(_vm.showMessage)?_c('v-row',{staticClass:"m-0 p-4"},[(_vm.successMessage.length)?_c('PBanner',{attrs:{"status":"success"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(_vm.errorMessage.length)?_c('PBanner',{attrs:{"status":"warning"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"py-6 px-3"},[_vm._m(0),_c('PCardHeader',{staticClass:"pl-2 mb-2",attrs:{"title":"Dropshipping discount"}}),_c('PCardSection',{staticClass:"p-0"},[_c('div',{staticClass:"row flex-column m-0"},[_c('PTextField',{class:!_vm.isValidGeneralDiscountPercentage
                    ? 'filed-title with-45-percent'
                    : 'filed-title with-45-percent general-discount-percentage',attrs:{"label":"Discount percentage","suffix":"%","id":"discount_percentage","type":"number"},model:{value:(_vm.supplierInfoCopy.total_disscount),callback:function ($$v) {_vm.$set(_vm.supplierInfoCopy, "total_disscount", $$v)},expression:"supplierInfoCopy.total_disscount"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isValidGeneralDiscountPercentage),expression:"isValidGeneralDiscountPercentage"}],staticClass:"error_animation error_messages"},[_c('svg',{staticClass:"Polaris-Icon__Svg error-icon",attrs:{"viewBox":"0 0 20 20","focusable":"false","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"}}),_c('path',{attrs:{"d":"M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"}})]),_vm._v(" Please enter Discount percentage ")]),_c('span',{staticClass:"discount-desc with-45-percent mt-3"},[_vm._v(" Please define the discount rate you offer to retailers by adding the number in percentage (%). It will apply to all the products you want to display. ")]),_c('div',{staticClass:"category-and-tag-section"},[_c('div',{staticClass:"container"})])],1)])],1),_c('div',{staticClass:"Categories-and-tags"},[_c('div',{staticClass:"px-5 d-flex flex-column gy-4"},[_vm._m(1),_c('div',{staticClass:"py-0 pl-0 shipping-pr"},[_c('p',{staticClass:"mb-2 filed-title d-flex justify-content-between"},[_vm._v(" Main Category ")]),_c('SelectDropOnboarding',{attrs:{"icon":'sort',"sortList":_vm.categories,"maxWidth":'100%',"placeholder":_vm.defaultMainCategoryName
                    ? _vm.defaultMainCategoryName.title
                    : 'choose main Category',"minWidth":'170px',"TextField":'title',"TextValue":'id'},on:{"sortbyobject":function($event){return _vm.assignCategory($event)}}}),_c('p',{staticClass:"discount-desc mt-2 px-0"},[_vm._v(" Please choose the main category of the products you offer. This category will be added to all of your products, but you can change them for individual products later. ")])],1),_c('div',{staticClass:"py-0 pl-0 shipping-pr"},[_c('p',{staticClass:"mb-2 filed-title"},[_vm._v("Choose product tags")]),_c('SelectDropOnboarding',{attrs:{"icon":'sort',"multiple":true,"sortList":_vm.tags,"maxAvailableChoice":3,"defaultMultipleSelected":_vm.selectedTags,"maxWidth":'100%',"placeholder":'No tags',"minWidth":'170px',"maxHeight":'130px',"TextField":'value',"TextValue":'id'},on:{"sortbyobject":function($event){return _vm.assignCategory($event, _vm.i.id)},"multiselectedobject":function($event){_vm.assignTag($event.multiSelectedObject.map((a) => a.id))}}}),_c('p',{staticClass:"discount-desc mt-2 px-0"},[_vm._v(" These tags will be added to all of your products, but you can change them for individual products later. ")])],1)])]),_c('PCardSection',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentRouteName === 'SupplierSetting'),expression:"currentRouteName === 'SupplierSetting'"}],staticClass:"shipping-info-buttons"},[_c('v-row',[_c('v-col',[_c('PButtonGroup',{},[_c('PButton',{attrs:{"primary":""},on:{"click":_vm.SaveChanges}},[_vm._v(" Save changes ")]),_c('PButton',{attrs:{"disabled":!_vm.valueChanged},on:{"click":_vm.DiscardChanges}},[_vm._v(" Discard changes ")])],1)],1)],1)],1)],1),_c('PStack',[_c('PStackItem',{staticClass:"advance-category-modal"},[_c('PModal',{attrs:{"large":"true","open":_vm.isModalActive,"sectioned":"","primaryAction":{
              content: 'Save changes',
              onAction: _vm.SaveChanges,
            },"secondaryActions":[
              { content: 'Discard', onAction: _vm.toggleCategoryModal },
            ],"title":"Advanced category mapping"},on:{"close":_vm.toggleCategoryModal}},[_c('p',{staticClass:"modal-desecration"},[_vm._v(" Map your collections to our categories and we'll categorize your products automatically. ")]),_c('PFormLayout',{staticClass:"d-flex"},[_c('div',{staticClass:"py-0 pl-0 w-100"},[_c('p',{staticClass:"mb-2 filed-title"},[_vm._v("Collection name")]),_c('PSelect',{staticStyle:{"border-radius":"4px !important"},attrs:{"placeholder":_vm.discountDetailsCopy.country || 'Choose Collection',"options":_vm.listOfCountries},on:{"change":function($event){return _vm.SelectCountry($event)}}})],1),_c('div',{staticClass:"py-0 pl-0 w-100"},[_c('p',{staticClass:"mb-2 filed-title"},[_vm._v("Choose related category")]),_c('PSelect',{staticStyle:{"border-radius":"4px !important"},attrs:{"placeholder":_vm.discountDetailsCopy.country || 'Choose Collection',"options":_vm.listOfCountries},on:{"change":function($event){return _vm.SelectCountry($event)}}})],1)])],1)],1)],1)],1):_c('div',{staticClass:"is-full-screen"},[_c('div',[_c('PSkeletonPage',{attrs:{"fullWidth":""}},[_c('PLayout',[_c('PLayoutSection',[_c('PCard',{attrs:{"sectioned":""}},[_c('PSkeletonBodyText')],1),_c('PCard',{attrs:{"sectioned":""}},[_c('PTextContainer',[_c('PSkeletonDisplayText',{attrs:{"size":"small"}}),_c('PSkeletonBodyText')],1)],1),_c('PCard',{attrs:{"sectioned":""}},[_c('PTextContainer',[_c('PSkeletonDisplayText',{attrs:{"size":"small"}}),_c('PSkeletonBodyText')],1)],1)],1)],1)],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Polaris-Card__Section pl-3"},[_c('div',{staticClass:"Polaris-TextContainer"},[_c('h2',{staticClass:"Polaris-Heading mb-5"},[_vm._v("Catalog management")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Polaris-Card__Section pl-0"},[_c('div',{staticClass:"Polaris-TextContainer"},[_c('h2',{staticClass:"Polaris-Heading mb-5"},[_vm._v("Categories & tags")])])])
}]

export { render, staticRenderFns }