<template>
  <main>
    <div class="catalog-management">
      <div v-if="!loading && renderComponent" class="is-full-screen">
        <div class="">
          <v-row class="m-0 p-4" v-if="showMessage">
            <PBanner status="success" v-if="successMessage.length">
              {{ successMessage }}
            </PBanner>
            <PBanner status="warning" v-if="errorMessage.length">
              {{ errorMessage }}
            </PBanner>
          </v-row>
          <div class="py-6 px-3">
            <div class="Polaris-Card__Section pl-3">
              <div class="Polaris-TextContainer">
                <h2 class="Polaris-Heading mb-5">Catalog management</h2>
              </div>
            </div>
            <PCardHeader class="pl-2 mb-2" title="Dropshipping discount" />
            <PCardSection class="p-0">
              <div class="row flex-column m-0">
                <PTextField
                  label="Discount percentage"
                  suffix="%"
                  id="discount_percentage"
                  type="number"
                  :class="
                    !isValidGeneralDiscountPercentage
                      ? 'filed-title with-45-percent'
                      : 'filed-title with-45-percent general-discount-percentage'
                  "
                  v-model="supplierInfoCopy.total_disscount"
                />
                <span
                  class="error_animation error_messages"
                  v-show="isValidGeneralDiscountPercentage"
                >
                  <svg
                    viewBox="0 0 20 20"
                    class="Polaris-Icon__Svg error-icon"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                    ></path>
                    <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                    ></path>
                  </svg>
                  Please enter Discount percentage
                </span>
                <span class="discount-desc with-45-percent mt-3">
                  Please define the discount rate you offer to retailers by
                  adding the number in percentage (%). It will apply to all the
                  products you want to display.
                </span>

                <div class="category-and-tag-section">
                  <div class="container"></div>
                </div>
              </div>
            </PCardSection>
          </div>
          <div class="Categories-and-tags">
            <div class="px-5 d-flex flex-column gy-4">
              <div class="Polaris-Card__Section pl-0">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading mb-5">Categories & tags</h2>
                </div>
              </div>
              <div class="py-0 pl-0 shipping-pr">
                <p class="mb-2 filed-title d-flex justify-content-between">
                  Main Category
                  <!-- <span
                    @click="toggleCategoryModal()"
                    class="cursor-pointer Polaris-Button__Text advance-mapping-title"
                  >
                    Advanced mapping
                  </span> -->
                </p>
                <SelectDropOnboarding
                  :icon="'sort'"
                  :sortList="categories"
                  :maxWidth="'100%'"
                  :placeholder="
                    defaultMainCategoryName
                      ? defaultMainCategoryName.title
                      : 'choose main Category'
                  "
                  :minWidth="'170px'"
                  :TextField="'title'"
                  :TextValue="'id'"
                  @sortbyobject="assignCategory($event)"
                />

                <p class="discount-desc mt-2 px-0">
                  Please choose the main category of the products you offer.
                  This category will be added to all of your products, but you
                  can change them for individual products later.
                </p>
              </div>
              <div class="py-0 pl-0 shipping-pr">
                <p class="mb-2 filed-title">Choose product tags</p>

                <SelectDropOnboarding
                  :icon="'sort'"
                  :multiple="true"
                  :sortList="tags"
                  :maxAvailableChoice="3"
                  :defaultMultipleSelected="selectedTags"
                  :maxWidth="'100%'"
                  :placeholder="'No tags'"
                  :minWidth="'170px'"
                  :maxHeight="'130px'"
                  :TextField="'value'"
                  :TextValue="'id'"
                  @sortbyobject="assignCategory($event, i.id)"
                  @multiselectedobject="
                    assignTag($event.multiSelectedObject.map((a) => a.id))
                  "
                />

                <p class="discount-desc mt-2 px-0">
                  These tags will be added to all of your products, but you can
                  change them for individual products later.
                </p>
              </div>
            </div>
          </div>
          <PCardSection
            class="shipping-info-buttons"
            v-show="currentRouteName === 'SupplierSetting'"
          >
            <v-row>
              <v-col>
                <PButtonGroup class="">
                  <PButton primary @click="SaveChanges"> Save changes </PButton>
                  <PButton @click="DiscardChanges" :disabled="!valueChanged">
                    Discard changes
                  </PButton>
                </PButtonGroup>
              </v-col>
            </v-row>
          </PCardSection>
        </div>
        <PStack>
          <PStackItem class="advance-category-modal">
            <PModal
              @close="toggleCategoryModal"
              large="true"
              :open="isModalActive"
              sectioned
              :primaryAction="{
                content: 'Save changes',
                onAction: SaveChanges,
              }"
              :secondaryActions="[
                { content: 'Discard', onAction: toggleCategoryModal },
              ]"
              title="Advanced category mapping"
            >
              <p class="modal-desecration">
                Map your collections to our categories and we'll categorize your
                products automatically.
              </p>
              <PFormLayout class="d-flex">
                <div class="py-0 pl-0 w-100">
                  <p class="mb-2 filed-title">Collection name</p>

                  <PSelect
                    style="border-radius: 4px !important"
                    :placeholder="
                      discountDetailsCopy.country || 'Choose Collection'
                    "
                    :options="listOfCountries"
                    @change="SelectCountry($event)"
                  />
                </div>
                <div class="py-0 pl-0 w-100">
                  <p class="mb-2 filed-title">Choose related category</p>

                  <PSelect
                    style="border-radius: 4px !important"
                    :placeholder="
                      discountDetailsCopy.country || 'Choose Collection'
                    "
                    :options="listOfCountries"
                    @change="SelectCountry($event)"
                  />
                </div>
              </PFormLayout>
            </PModal>
          </PStackItem>
        </PStack>
      </div>
      <div class="is-full-screen" v-else>
        <div>
          <PSkeletonPage fullWidth>
            <PLayout>
              <PLayoutSection>
                <PCard sectioned=""><PSkeletonBodyText /></PCard>
                <PCard sectioned="">
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText />
                  </PTextContainer>
                </PCard>
                <PCard sectioned="">
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText />
                  </PTextContainer>
                </PCard>
              </PLayoutSection>
            </PLayout>
          </PSkeletonPage>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// services
import CommonData from "@/services/CommonData"; // Components
import SupplierSetting from "@/services/SupplierSetting";
import headerNavigation from "@/views/shopify/components/headerNavigation.vue";
import ShippingTo from "@/components/supplier/ShippingTo.vue";
import SelectDropOnboarding from "@/components/bases/SelectDropOnboarding.vue";
// helpers
import { mapGetters, mapActions } from "vuex";
import AddNewDiscount from "./AddNewDiscount.vue";

export default {
  components: {
    headerNavigation,
    ShippingTo,
    AddNewDiscount,
    SelectDropOnboarding,
  },
  data() {
    return {
      isModalActive: false,
      loading: true,
      categories: [],
      tags: [],
      selectedTags: [],
      newDiscountList: [],
      generalDiscountPercentage: "",
      isValidGeneralDiscountPercentage: false,
      shippingDiscountDetails: [],
      shippingDetails: [],
      discountDetailsCopy: [],
      showMessage: false,
      renderComponent: true,
      isSavingChanges: false,
      isShopify: true,
      supplierInfoCopy: {},
      countries: [],
      successMessage: "",
      errorMessage: "",
      discard: true,
      defaultMainCategoryName: "",
    };
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters({
      connectedSupplier: "supplier/supplierConnected",
      supplierInfo: "supplier/supplierInfo",
    }),
    listOfCountries() {
      // array of object with value and name keys from this.countries
      const listOfCountries = this.countries.map((country) => {
        return { label: country.name, value: country.id };
      });
      return listOfCountries;
    },
    valueChanged() {
      return (
        JSON.stringify(this.supplierInfo) !==
        JSON.stringify(this.supplierInfoCopy)
      );
    },
  },
  async created() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
    }

    await this["supplier/getConnectedSupplier"]();
    await this["supplier/getSupplierInformation"]();
    if (this.supplierInfo) {
      this.supplierInfoCopy = JSON.parse(JSON.stringify(this.supplierInfo));
      this.generalDiscountPercentage = this.supplierInfo.total_disscount;
      this.loading = false;
    }
  },

  watch: {
    supplierInfoCopy: {
      handler(val) {
        if (val.total_disscount == 0 || val.total_disscount == "") {
          this.isValidGeneralDiscountPercentage = true;
        } else {
          this.isValidGeneralDiscountPercentage = false;
        }
      },
      deep: true,
    },
    generalDiscountPercentage(value) {
      if (this.isValidGeneralDiscountPercentage && value !== "") {
        this.isValidGeneralDiscountPercentage = false;
      }
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getTags();

    if (this.supplierInfo.default_main_category) {
      this.defaultMainCategoryName = this.categories.find(
        (category) =>
          category.id == parseInt(this.supplierInfo.default_main_category)
      );
       
    }
  },
  methods: {
    getCategories() {
      CommonData.getCategories()
        .then((response) => {
           

          this.categories = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    assignTag(e) {
      this.supplierInfoCopy.default_main_tags = e.join(",");
    },
    assignCategory(e) {
      this.supplierInfoCopy.default_main_category =
        e.sort_by_object.id.toString();
    },
    toggleCategoryModal() {
      this.isModalActive = !this.isModalActive;
    },
    ...mapActions([
      "supplier/getConnectedSupplier",
      "supplier/getSupplierInformation",
      "supplier/updateSupplierInformation",
      "alert/clear",
    ]),

    async getTags() {
      try {
        const res = await CommonData.getTags();
        let selectedDefaultTags = [];

        if (res) this.tags = res.data.values;
        if (this.supplierInfo.default_main_tags) {
          let default_main_tags =
            this.supplierInfo.default_main_tags.split(",");
          default_main_tags = default_main_tags.map(Number);

          default_main_tags.forEach((default_main_tag) => {
            this.tags.map(function (tag) {
              if (tag.id === default_main_tag) {
                selectedDefaultTags.push(tag);
              }
            });
          });

          this.selectedTags = selectedDefaultTags;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async SaveChanges() {
      if (
        this.supplierInfoCopy.total_disscount == 0 ||
        this.supplierInfoCopy.total_disscount == "" ||
        this.isValidGeneralDiscountPercentage
      ) {
        this.isValidGeneralDiscountPercentage = true;
        window.scrollTo(0, 0);
        return;
      }

      if (this.valueChanged) {
        const response = await SupplierSetting.updategetSupplierDetail(
          this.supplierInfoCopy
        );
        if (response) {
          window.scrollTo(0, 0);
          this.successMessage = "Your changes have been saved";
          this.showMessage = true;
          if (this.currentRouteName !== "SupplierSetting") {
            setTimeout(() => {
              this.showMessage = false;
              this.successMessage = "";
              this.$router.push("/supplier/assign-categoryV2");
            }, 2000);
          }
        } else {
          window.scrollTo(0, 0);
          this.errorMessage = "Something went wrong";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
        }
      } else {
        window.scrollTo(0, 0);
        this.errorMessage =
          "No value changed. Please update your informations first.";
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
          this.errorMessage = "";
        }, 3000);
      }
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    DiscardChanges() {
      if (
        JSON.stringify(this.supplierInfoCopy) !==
        JSON.stringify(this.supplierInfo)
      ) {
        this.supplierInfoCopy = JSON.parse(JSON.stringify(this.supplierInfo));
        this.forceRerender();
      }
    },
  },
};
</script>

<style lang="scss">
.catalog-management {
  .shipping-info-buttons {
    border-top: 0.1rem solid #e1e3e5;
    padding: 20px;
    margin-top: 20px;
  }
  .Polaris-Select {
    border: 0.1rem solid #c9cccf !important;
    border-radius: 4px !important;
  }
  .advance-category-modal {
    .modal-desecration {
      color: #202223;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .Polaris-Modal-Dialog__Modal {
      @include md {
        height: 100vh;
      }
    }
    .Polaris-Modal__BodyWrapper {
      overflow-x: auto;
    }
  }

  .Categories-and-tags {
    border-top: 1px solid #c4c4c4;
  }
  .advance-mapping-title {
    color: #2c6ecb;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: all 0.2s ease-out;
    &:hover {
      text-decoration: underline;
    }
  }
  .discount-desc {
    color: #6d7175;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .filed-title {
    color: #202223;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .with-45-percent {
    width: 45%;
    @include lg {
      width: 100%;
    }
  }
  .shipping-pr {
    width: 45%;
    padding-right: 12px;
    @include lg {
      padding-right: 0;
      width: 100%;
    }
  }
  .shipping-pl {
    padding-right: 12px;
    @include lg {
      padding-right: 0;
    }
  }
  .shipping-pr-6 {
    padding-right: 24px !important;
    @include lg {
      padding: 0 !important;
    }
  }
  .shipping-px-6 {
    padding: 0 24px !important;
    @include lg {
      padding: 0 !important;
    }
  }

  .general-discount-percentage {
    .Polaris-TextField__Backdrop {
      background-color: #fde2dd;
      border-color: #c5280c;
    }
  }

  .error_messages {
    display: inline-flex;
    padding: 5px;
    color: #c5280c;
    margin-top: 7px;
    font-size: 14px;
    font-weight: 500;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .error-icon {
    width: 20px;
    height: 20px;
    color: #c5280c;
    filter: brightness(0) saturate(100%) invert(23%) sepia(90%) saturate(7418%)
      hue-rotate(16deg) brightness(89%) contrast(91%);
  }
  .error_animation {
    animation: errorFadeInDown 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .style_err {
    border: 1px solid#c5280c;
  }
  @keyframes errorFadeInDown {
    0% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }

    59% {
      opacity: 1;
      transform: skewX(20deg);
    }
    70%,
    90% {
      transform: skewX(-20deg);
    }
    100% {
      -webkit-transform: none;
      transform: none;
    }
  }
}
</style>
