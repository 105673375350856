<template>
  <main>
    <div class="shipping-info-section">
      <div v-if="!loading && renderComponent" class="is-full-screen">
        <ValidationObserver ref="form" v-slot="{}">
          <v-row class="m-0 p-4" v-if="true">
            <PBanner status="success" v-if="successMessage.length">
              {{ successMessage }}
            </PBanner>
            <PBanner status="warning" v-if="errorMessage.length">
              {{ errorMessage }}
            </PBanner>
          </v-row>
          <form novalidate @submit.prevent="validate" class="py-6 px-3 pt-0">
            <div class="Polaris-Card__Section pt-0">
              <div class="Polaris-TextContainer">
                <h2 class="Polaris-Heading mb-5">Shipping Information</h2>
              </div>
            </div>
            <div class="Polaris-FormLayout px-6 ml-0">
              <v-row
                class="d-flex align-start m-0 gy-4 justify-content-between"
              >
                <v-col sm="12" md="6" class="py-0 pl-0 shipping-pr">
                  <p class="mb-2 filed-title">
                    Primary warehouse location - Country
                  </p>

                  <div class="form-group">
                    <ValidationProvider
                      name="Country"
                      rules="required"
                      v-slot="{ errors }"
                      class="input-wrapper"
                    >
                      <PSelect
                        :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                        style="border-radius: 4px !important"
                        :placeholder="
                          shippingDetailsCopy.country || 'Select Country'
                        "
                        :options="listOfCountries"
                        @change="SelectCountryAndGetProvincesList($event)"
                      />
                      <input
                        v-show="false"
                        id="Country"
                        v-model="shippingDetailsCopy.country"
                        name="Country"
                        type="text"
                        class="form-control"
                        placeholder="Please enter your name"
                        :class="{ style_err: errors[0] }"
                      />
                      <span
                        class="error_animation error_messages"
                        v-show="errors[0]"
                      >
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg error-icon"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                          ></path>
                          <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                          ></path>
                        </svg>
                        Please select Primary warehouse location
                      </span>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col
                  sm="12"
                  md="6"
                  xs="12"
                  lg="6"
                  cols="12"
                  class="py-0 pl-0 shipping-pl pr-0"
                >
                  <p class="filed-title mb-2">
                    Main warehouse location - State / Province
                  </p>

                  <ValidationProvider
                    name="province"
                    rules="required"
                    v-slot="{ errors }"
                    class="input-wrapper"
                  >
                    <PSelect
                      :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                      style="border-radius: 4px !important"
                      :placeholder="
                        shippingDetailsCopy.province ||
                        'Choose the state  / Province'
                      "
                      :options="listOfProvinces"
                      @change="selectProvince($event)"
                    />
                    <input
                      v-show="false"
                      id="province"
                      v-model="selectedProvince"
                      name="province"
                      type="text"
                      class="form-control"
                      placeholder="Please enter your name"
                      :class="{ style_err: errors[0] }"
                    />
                    <span
                      class="error_animation error_messages"
                      v-show="errors[0]"
                    >
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg error-icon"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                        ></path>
                        <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                        ></path>
                      </svg>
                      Please select Province
                    </span>
                  </ValidationProvider>
                </v-col>
                <v-col
                  sm="12"
                  md="6"
                  xs="12"
                  lg="6"
                  cols="12"
                  class="py-0 pl-0 shipping-pr mb-auto"
                >
                  <p class="mb-2 filed-title">Processing day</p>

                  <ValidationProvider
                    name="proccessing_time"
                    rules="required"
                    v-slot="{ errors }"
                    class="input-wrapper"
                  >
                    <PTextField
                      :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                      v-model="shippingDetailsCopy.proccessing_time"
                      id="shipping_from_prcessing_time"
                      suffix="day"
                      type="number"
                    />
                    <input
                      v-show="false"
                      id="proccessing_time"
                      v-model="shippingDetailsCopy.proccessing_time"
                      name="proccessing_time"
                      type="number"
                      class="form-control"
                      placeholder="Please enter your name"
                      :class="{ style_err: errors[0] }"
                    />
                    <span
                      class="error_animation error_messages"
                      v-show="errors[0]"
                    >
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg error-icon"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                        ></path>
                        <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                        ></path>
                      </svg>
                      Please enter Processing day
                    </span>
                  </ValidationProvider>
                </v-col>

                <v-col
                  sm="12"
                  md="6"
                  xs="12"
                  lg="6"
                  cols="12"
                  class="py-0 pl-0 shipping-pl pr-0 mb-auto"
                >
                  <p class="mb-2 filed-title">Refund terms</p>

                  <ValidationProvider
                    name="refund_terms"
                    rules="required"
                    v-slot="{ errors }"
                    class="input-wrapper"
                  >
                    <PTextField
                      :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                      :placeholder="`ex: ${shippingDetailsCopy.refund_terms} `"
                      v-model="shippingDetailsCopy.refund_terms"
                      id="shipping_from_refund_terms"
                      suffix="day"
                      type="number"
                    />
                    <input
                      v-show="false"
                      id="refund_terms"
                      v-model="shippingDetailsCopy.refund_terms"
                      name="refund_terms"
                      type="text"
                      class="form-control"
                      placeholder="Please enter your name"
                      :class="{ style_err: errors[0] }"
                    />
                    <span
                      class="error_animation error_messages"
                      v-show="errors[0]"
                    >
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg error-icon"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                        ></path>
                        <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                        ></path>
                      </svg>
                      Please enter Refund terms
                    </span>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div class="Polaris-Card__Section mt-6">
              <div class="Polaris-TextContainer">
                <h2 class="Polaris-Heading">Shipping To</h2>
              </div>
            </div>
            <div class="Polaris-FormLayout px-6 m-0">
              <div class="shipping_to_static">
                <v-row class="align-start m-0 gy-4">
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="pa-0"
                    style="width: 32.7%"
                  >
                    <p class="mb-2 filed-title">Country</p>

                    <PSelect
                      style="
                        border: 0.1rem solid rgb(201, 204, 207) !important;
                        border-radius: 4px !important;
                      "
                      placeholder="Domestic"
                      :options="listOfCountries"
                      @change="
                        SetShipToCountry($event, shippingDetailsCopy.lines[0])
                      "
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="3" class="py-0 shipping-px-6">
                    <p class="mb-2 filed-title">Shipping time</p>

                    <ValidationProvider
                      name="shipping_time"
                      rules="required"
                      v-slot="{ errors }"
                      class="input-wrapper"
                    >
                      <PSelect
                        :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                        style="
                          border: 0.1rem solid rgb(201, 204, 207) !important;
                          border-radius: 4px !important;
                        "
                        :placeholder="
                          shippingDetailsCopy.lines[0].delivery_days ||
                          'Select Shipping time'
                        "
                        v-model="shippingDetailsCopy.lines[0].delivery_days"
                        :options="[
                          { label: '1 - 3 days', value: '1 - 3 days' },
                          { label: '3 - 7 days', value: '3 - 7 days' },
                          { label: '7 - 14 days', value: '7 - 14 days' },
                        ]"
                      />
                      <input
                        v-show="false"
                        id="shipping_time"
                        v-model="shippingDetailsCopy.lines[0].delivery_days"
                        name="shipping_time"
                        type="text"
                        class="form-control"
                        placeholder="Please enter your name"
                        :class="{ style_err: errors[0] }"
                      />
                      <span
                        class="error_animation error_messages"
                        v-show="errors[0]"
                      >
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg error-icon"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                          ></path>
                          <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                          ></path>
                        </svg>
                        Please enterShipping time
                      </span>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="pa-0 shipping-pr-6"
                    style="width: 12.7%; padding-left: 13px"
                  >
                    <p class="mb-2 filed-title">Currency</p>

                    <ValidationProvider
                      name="Currency"
                      rules="required"
                      v-slot="{ errors }"
                      class="input-wrapper"
                    >
                      <PSelect
                        :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                        style="
                          border: 0.1rem solid rgb(201, 204, 207) !important;
                          border-radius: 4px !important;
                        "
                        :placeholder="
                          shippingDetailsCopy.lines[0].currency ||
                          'Choose the currency'
                        "
                        :options="currency"
                        v-model="shippingDetailsCopy.lines[0].currency"
                      />
                      <input
                        v-show="false"
                        id="Currency"
                        v-model="shippingDetailsCopy.lines[0].currency"
                        name="currency"
                        type="text"
                        class="form-control"
                        placeholder="Please enter your name"
                        :class="{ style_err: errors[0] }"
                      />
                      <span
                        class="error_animation error_messages"
                        v-show="errors[0]"
                      >
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg error-icon"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                          ></path>
                          <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                          ></path>
                        </svg>
                        Please select the currency
                      </span>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    class="pa-0"
                    style="width: 20%; padding-left: 17px"
                  >
                    <p class="mb-2 filed-title">Shipping cost</p>

                    <ValidationProvider
                      name="price"
                      rules="required"
                      v-slot="{ errors }"
                      class="input-wrapper"
                    >
                      <PTextField
                        :class="errors[0] ? 'w-100 filed-has-error' : ' w-100'"
                        v-model="shippingDetailsCopy.lines[0].price"
                        id="shipping_to_cost"
                        type="number"
                      />
                      <input
                        v-show="false"
                        id="price"
                        v-model="shippingDetailsCopy.lines[0].price"
                        name="price"
                        type="text"
                        class="form-control"
                        placeholder="Please enter your name"
                        :class="{ style_err: errors[0] }"
                      />
                      <span
                        class="error_animation error_messages"
                        v-show="errors[0]"
                      >
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg error-icon"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"
                          ></path>
                          <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"
                          ></path>
                        </svg>
                        Please enterShipping cost
                      </span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </div>
              <div
                class="shipping_to_dynamic pt-6"
                v-if="
                  shippingDetailsCopy.lines &&
                  shippingDetailsCopy.lines.length > 1
                "
              >
                <ShippingTo
                  v-for="(line, index) in shippingDetailsCopy.lines.slice(1)"
                  :key="index"
                  :shippingTo="line"
                  :countries="countries"
                  @removeshipto="RemoveShipTo(line)"
                  @shippingtochange="ChangeShipTo($event, index + 1)"
                ></ShippingTo>
              </div>
              <div>
                <div class="mt-8">
                  <button
                    class="Polaris-Button Polaris-Button--plain"
                    type="button"
                    @click="AddShippingTo()"
                  >
                    <span class="Polaris-Button__Content">
                      <span
                        class="Polaris-Button__Text text-decoration-underline"
                      >
                        Add a new destination
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <PCardSection
              class="shipping-info-buttons"
              v-show="currentRouteName === 'SupplierSetting'"
            >
              <v-row>
                <v-col>
                  <PButtonGroup class="">
                    <PButton primary @click="SaveChanges">
                      Save changes
                    </PButton>
                    <PButton @click="DiscardChanges" :disabled="!valueChanged">
                      Discard changes
                    </PButton>
                  </PButtonGroup>
                </v-col>
              </v-row>
            </PCardSection>
          </form>
        </ValidationObserver>
      </div>
      <div class="is-full-screen" v-else>
        <div>
          <PSkeletonPage fullWidth>
            <PLayout>
              <PLayoutSection>
                <PCard sectioned=""><PSkeletonBodyText /></PCard>
                <PCard sectioned="">
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText />
                  </PTextContainer>
                </PCard>
                <PCard sectioned="">
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText />
                  </PTextContainer>
                </PCard>
              </PLayoutSection>
            </PLayout>
          </PSkeletonPage>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// services
import CommonData from "@/services/CommonData";
import SupplierShipping from "@/services/SupplierShipping";
// Components
import headerNavigation from "@/views/shopify/components/headerNavigation.vue";
import ShippingTo from "@/components/supplier/ShippingTo.vue";
// helpers
import { currency } from "@/utils/currency";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: {
    headerNavigation,
    ShippingTo,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: true,
      countries: [],
      provinces: [],
      selectedProvince: "",
      shippingFromCities: [],
      shippingToCities: [],
      shipToCity: "",
      shipping_to_length: [],
      currency: currency,
      shipping_from_country: {},
      shipping_from_city: {},
      shippingDetails: [],
      shippingDetailsCopy: [],
      showMessage: false,
      successMessage: "",
      errorMessage: "",
      renderComponent: true,
      isSavingChanges: false,
    };
  },
  async created() {
    await this.GetShippingsDetail();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    listOfCountries() {
      // array of object with value and name keys from this.countries
      const listOfCountries = this.countries.map((country) => {
        return { label: country.name, value: country.id };
      });

      return listOfCountries;
    },
    listOfProvinces() {
      // array of object with value and name keys from this.provinces
      const listOfProvinces = this.provinces.map((province) => {
        return { label: province.name, value: province.id };
      });

      return listOfProvinces;
    },

    listOfCitiesToShipFrom() {
      // array of object with value and name keys from this.countries
      const listOfCitiesToShip = this.shippingFromCities.map((city) => {
        return { label: city.name, value: city.id };
      });
      return listOfCitiesToShip;
    },
    listOfShippingTo() {
      // array of object with value and name keys from this.countries
      return this.shippingDetailsCopy.lines.slice(1);
    },
    valueChanged() {
      return (
        JSON.stringify(this.shippingDetails) !==
        JSON.stringify(this.shippingDetailsCopy)
      );
    },
  },

  methods: {
    async validate() {
      const result = await this.$refs.form.validate().then(async (success) => {
        // If client-side validation passes, move into this block.
        if (!success) {
          return false;
        } else {
          this.SaveChanges();
        }
      });
      return result;
    },
    async GetCountries() {
      try {
        const response = await CommonData.getCountries();
        this.countries = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getProvinces(countryID) {
      try {
        const response = await CommonData.getProvinces(countryID);
        this.provinces = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    selectProvince(provinceID) {
      const foundedProvince = this.provinces.find(
        (province) => province.id === provinceID
      );
      this.selectedProvince = foundedProvince.name;

      this.shippingDetailsCopy.province = foundedProvince.name;
    },
    async GetShippingsDetail() {
      try {
        const response = await SupplierShipping.getShippingsDetail();

        this.shippingDetails = response.data;
        this.selectedProvince = this.shippingDetails.province;
        this.shippingDetailsCopy = JSON.parse(
          JSON.stringify(this.shippingDetails)
        );

        this.shipToCity = this.shippingDetailsCopy.city;
        await this.GetCountries();

        const selectedCountry = await this.countries.find(
          async (country) =>
            (await country.name) === this.shippingDetailsCopy.country
        );

        await this.getProvinces(selectedCountry.id);

        this.loading = false;
      } catch (error) {
        console.log("shipping errors", error);
      }
    },

    async SelectCountryAndGetProvincesList(countryID) {
      try {
        const response = await CommonData.getProvinces(countryID);

        this.provinces = response.data;
        const countryFound = this.countries.find(
          (country) => country.id === countryID
        );
        this.shippingDetailsCopy.country = countryFound.name;
        this.shippingDetailsCopy.country_iso2 = countryFound.iso2;
        this.shippingDetailsCopy.lines[0].country = countryFound.name;
        this.shippingDetailsCopy.lines[0].countryISO2 = countryFound.iso2;
        this.shippingDetailsCopy.province = null;
        this.shipToCity = null;
      } catch (error) {
        console.log(error);
      }
    },
    AddShippingTo() {
      const lineslength = this.shippingDetailsCopy.lines.length;
      this.shippingDetailsCopy.lines.push({
        country: `Select country ${lineslength}`,
        countryISO2: "",
        currency: "Select currency",
        delivery_days: "Select duration",
        price: 0,
      });
    },
    RemoveShipTo(lineToRemove) {
      const found = this.shippingDetailsCopy.lines.find(
        (ship) => ship.country == lineToRemove.country
      );
      if (found)
        this.shippingDetailsCopy.lines = this.shippingDetailsCopy.lines.filter(
          (line) => {
            return line != found;
          }
        );
    },
    CountryNameFromCountryISO(countryISO) {
      if (countryISO) {
        const country = this.countries.find(
          (country) => country.iso2 === countryISO
        );

        return country.name;
      }
    },
    SetShipFromCity(cityID) {
      const cityFound = this.shippingFromCities.find(
        (city) => city.id === cityID
      );
      if (cityFound) {
        this.shippingDetailsCopy.city = cityFound.name;
      }
    },
    SetShipToCountry(countryID, previousCountry) {
      const selectedCountry = this.countries.find(
        (country) => country.id === countryID
      );
      if (selectedCountry) {
        this.shippingDetailsCopy.lines.map((line) => {
          if (
            line.countryISO2 === previousCountry.countryISO2 ||
            line.country === previousCountry.country
          ) {
            line.country = selectedCountry.name;
            line.countryISO2 = selectedCountry.iso2;
          }
        });
      }
    },
    ChangeShipTo(newLine, key) {
      this.shippingDetailsCopy.lines[key] = newLine;
    },
    async SaveChanges() {
      this.isSavingChanges = true;
      if (
        JSON.stringify(this.shippingDetails) !==
        JSON.stringify(this.shippingDetailsCopy)
      ) {
        const foundUnvalidedLine = this.shippingDetailsCopy.lines.find(
          (line) => {
            return (
              !line.country ||
              line.country.startsWith("Select country") ||
              line.countryISO2.length === 0 ||
              !line.currency ||
              line.currency.startsWith("Select currency") ||
              line.delivery_days.startsWith("Select duration")
            );
          }
        );
        if (foundUnvalidedLine) {
          this.errorMessage =
            "Please check your shipping destinations (shipping to) details";
          this.showMessage = true;
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          this.isSavingChanges = false;
          return;
        }

        this.shippingDetailsCopy.lines[0].primary = true;
        // Here ae add to the shipping details two new fields:
        // 1. primary - boolean - if true, this is the primary shipping details meaning the default one
        // 2. min_delivery_days - int - we need this value for the retailer so we can show the correct min shipping time
        // 3. max_delivery_days - int - we need this value for the retailer so we can show the correct max shipping time
        for (let i = 0; i < this.shippingDetailsCopy.lines.length; i++) {
          const element = this.shippingDetailsCopy.lines[i];
          if (i === 0) element.primary = true;
          else element.primary = false;

          const deleviryDays = element.delivery_days.split(" ");

          element.min_delivery_days = Number(deleviryDays[0]);
          element.max_delivery_days = Number(deleviryDays[2]);
        }

        const response = await SupplierShipping.updateShippingsDetail(
          this.shippingDetailsCopy
        );
        if (response) {
          window.scrollTo(0, 0);
          this.successMessage = "Your changes have been saved";
          this.showMessage = true;
          if (this.currentRouteName !== "SupplierSetting") {
            this.$emit("change-step", "CatalogManagement");
          }
          setTimeout(() => {
            this.showMessage = false;
            this.successMessage = "";
          }, 3000);
          this.isSavingChanges = false;
        } else {
          window.scrollTo(0, 0);
          this.errorMessage = "Something went wrong";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          this.isSavingChanges = false;
        }
      } else {
        window.scrollTo(0, 0);

        this.errorMessage =
          "No value changed. Please update your informations first.";
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
          this.errorMessage = "";
        }, 3000);
        this.isSavingChanges = false;
      }
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    DiscardChanges() {
      if (
        JSON.stringify(this.shippingDetails) !==
        JSON.stringify(this.shippingDetailsCopy)
      ) {
        this.shippingDetailsCopy = JSON.parse(
          JSON.stringify(this.shippingDetails)
        );
        this.forceRerender();
      }
    },
  },
};
</script>

<style lang="scss">
.shipping-info-section {
  .shipping-info-buttons {
    border-top: 0.1rem solid #e1e3e5;
    padding: 20px;
    margin-top: 20px;
  }
  .Polaris-Card__Section {
    @include md {
      padding-left: 0 !important;
    }
  }
  .Polaris-FormLayout {
    @include md {
      padding: 0 !important;
    }
  }
  .Polaris-Select {
    border: 0.1rem solid #c9cccf !important;
    border-radius: 4px !important;
  }

  .filed-title {
    color: #202223;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .shipping-pr {
    padding-right: 12px;
    @include lg {
      padding-right: 0;
    }
  }
  .shipping-pl {
    padding-right: 12px;
    @include lg {
      padding-right: 0;
    }
  }
  .shipping-pr-6 {
    padding-right: 24px !important;
    @include lg {
      padding: 0 !important;
    }
  }
  .shipping-px-6 {
    padding: 0 24px !important;
    @include lg {
      padding: 0 !important;
    }
  }
  .filed-has-error {
    .Polaris-TextField__Backdrop,
    .Polaris-Select__Backdrop {
      background-color: #fde2dd !important;
      border-color: #c5280c;
    }
  }

  .error_messages {
    display: inline-flex;
    padding: 5px;
    color: #c5280c;
    margin-top: 7px;
    font-size: 14px;
    font-weight: 500;
  }
  .error-icon {
    width: 20px;
    height: 20px;
    color: #c5280c;
    filter: brightness(0) saturate(100%) invert(23%) sepia(90%) saturate(7418%)
      hue-rotate(16deg) brightness(89%) contrast(91%);
  }
  .error_animation {
    animation: errorFadeInDown 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .style_err {
    border: 1px solid#c5280c;
  }
  @keyframes errorFadeInDown {
    0% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }

    59% {
      opacity: 1;
      transform: skewX(20deg);
    }
    70%,
    90% {
      transform: skewX(-20deg);
    }
    100% {
      -webkit-transform: none;
      transform: none;
    }
  }
}
</style>
