<template>
  <main class="general-information-section" v-if="isShopify">
    <div v-if="!loading && renderComponent">
      <div class="page-wrapper-shopify is-full-screen">
        <div class=" ">
          <v-row class="m-0 p-4" v-if="showMessage">
            <PBanner status="success" v-if="successMessage.length">
              {{ successMessage }}
            </PBanner>
            <PBanner status="warning" v-if="errorMessage.length">
              {{ errorMessage }}
            </PBanner>
          </v-row>
          <div class=" ">
            <PCardHeader title="General Information " />
            <PCardSection>
              <div class="field-container">
                <PTextField
                  class="col-xl-6 col-lg-6 col-md-6 px-0"
                  label="Brand name"
                  labelClass="customize-label"
                  id="brand_name"
                  type="text"
                  :placeholder="supplierInfoCopy.brand_name"
                  v-model="supplierInfoCopy.brand_name"
                />

                <PSelect
                  class="col-xl-6 col-lg-6 col-md-6 px-0 general-info-select"
                  label="Made in"
                  labelClass="customize-label"
                  :placeholder="supplierInfoCopy.collection_country_name"
                  :options="listOfCountries"
                  @change="SetNewCountry($event)"
                  :error="
                    supplierInfoCopy.collection_country_name === ''
                      ? 'Collection origin is required'
                      : ''
                  "
                />
                <PTextField
                  class="col-xl-6 col-lg-6 col-md-6 px-0 general-info-select"
                  label="Contact email"
                  id="brand_name"
                  type="text"
                  :placeholder="supplierInfoCopy.contact_email"
                  v-model="supplierInfoCopy.contact_email"
                />
                <PTextField
                  class="general-info-select"
                  label="Brand story"
                  id="brand_story"
                  type="text"
                  multiline
                  :minHeight="100"
                  :placeholder="
                    supplierInfoCopy.brand_story
                      ? supplierInfoCopy.brand_story
                      : 'Enter a description bout your mission, your products attributes and, why your brand is unique ...'
                  "
                  v-model="supplierInfoCopy.brand_story"
                />
                <PTextField
                  class="general-info-select"
                  label="Supplier policy (Optional)"
                  id="brand_story"
                  type="text"
                  multiline
                  :minHeight="100"
                  :placeholder="supplierInfoCopy.supplier_policy"
                  v-model="supplierInfoCopy.supplier_policy"
                />
              </div>

              <!-- <v-row class="mt-6">
                  <PTextField
                    label="Brand story"
                    id="brand_story"
                    type="text"
                    multiline
                    :minHeight="100"
                    :placeholder="supplierInfoCopy.brand_story"
                    helpText="Will be visible to Retailers on product detail page."
                    v-model="supplierInfoCopy.brand_story"
                    :error="
                      supplierInfoCopy.brand_story === ''
                        ? 'Brand story is required'
                        : ''
                    "
                  />
                </v-row> -->
            </PCardSection>
            <!-- <PCardHeader title="Dicount information" /> -->
            <PCardSection v-show="currentRouteName === 'SupplierSetting'">
              <v-row>
                <v-col>
                  <PButtonGroup class="">
                    <PButton primary @click="SaveChanges">
                      Save changes
                    </PButton>
                    <PButton @click="DiscardChanges" :disabled="!valueChanged">
                      Discard changes
                    </PButton>
                  </PButtonGroup>
                </v-col>
              </v-row>
            </PCardSection>

            <!-- <PCard class="pa-6">
              <PHeading element="h1">Your VGANG Supplier Account</PHeading>
              <PTextContainer>
                <p>
                  Shopify uses this account to manage and sync with you VGANG
                  Supplier account.
                </p>
              </PTextContainer>
              <v-row>
                <v-col class="d-flex align-center">
                  <PAvatar customer name="Avatar-picture" />
                  <p class="ma-0 pl-1">
                    {{ connectedSupplier.userEmail || "..." }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <PButton @click="DisconnectUser"> Disconnect </PButton>
                </v-col>
              </v-row>
            </PCard> -->
          </div>
        </div>
      </div>
    </div>
    <div class="page-wrapper-shopify is-full-screen" v-else>
      <div class=" ">
        <PSkeletonPage fullWidth>
          <PLayout>
            <PLayoutSection>
              <PCard sectioned="" class="w-50">
                <PTextContainer>
                  <PSkeletonDisplayText size="large" />
                  <PSkeletonBodyText :lines="0" />
                </PTextContainer>
              </PCard>
              <PCard sectioned="" class="w-50">
                <PTextContainer>
                  <PSkeletonDisplayText size="large" />
                  <PSkeletonBodyText :lines="0" />
                </PTextContainer>
              </PCard>
              <PCard sectioned="" class="w-50">
                <PTextContainer>
                  <PSkeletonDisplayText size="large" />
                  <PSkeletonBodyText :lines="0" />
                </PTextContainer>
              </PCard>
              <PCard sectioned="">
                <PTextContainer>
                  <PSkeletonDisplayText size="small" />
                  <PSkeletonBodyText />
                </PTextContainer>
              </PCard>
              <PCard sectioned="">
                <PTextContainer>
                  <PSkeletonDisplayText size="small" />
                  <PSkeletonBodyText />
                </PTextContainer>
              </PCard>
            </PLayoutSection>
          </PLayout>
        </PSkeletonPage>
      </div>
    </div>
  </main>
</template>
<script>
import headerNavigation from "@/views/shopify/components/headerNavigation.vue";
import CommonData from "@/services/CommonData";
// Services
import { removeVariable } from "@/utils/conditionalStoreVariables";
import AuthService from "@/services/AuthenticationService";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { headerNavigation },

  data() {
    return {
      loading: true,
      isShopify: true,
      userEmail: "...",
      supplierInfoCopy: {},
      countries: [],
      successMessage: "",
      errorMessage: "",
      showMessage: false,
      discard: true,
      renderComponent: true,
      brandNameRequired: "",
      CollectionRequired: "",
      brandStoryRequired: "",
      discountPourcentageRequired: "",
    };
  },
  async created() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
    }
    await this.GetCountries();

    await this["supplier/getConnectedSupplier"]();
    await this["supplier/getSupplierInformation"]();
    if (this.supplierInfo) {
      this.supplierInfoCopy = JSON.parse(JSON.stringify(this.supplierInfo));
      this.loading = false;
    }
    if (!this.supplierInfoCopy.collection_country_name) {
      this.SetNewCountry(236);
    }
  },

  computed: {
    ...mapGetters({
      connectedSupplier: "supplier/supplierConnected",
      supplierInfo: "supplier/supplierInfo",
    }),
    listOfCountries() {
      // array of object with value and name keys from this.countries
      const listOfCountries = this.countries.map((country) => {
        return { label: country.name, value: country.id };
      });
      return listOfCountries;
    },
    valueChanged() {
      return (
        JSON.stringify(this.supplierInfo) !==
        JSON.stringify(this.supplierInfoCopy)
      );
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions([
      "supplier/getConnectedSupplier",
      "supplier/getSupplierInformation",
      "supplier/updateSupplierInformation",
      "alert/clear",
    ]),

    async SaveChanges() {
      if (this.valueChanged) {
        if (
          this.supplierInfoCopy.brand_name === "" ||
          this.supplierInfoCopy.collection_country_name === "" ||
          this.supplierInfoCopy.brand_story === "" ||
          this.supplierInfoCopy.brand_policy === ""
        ) {
          this.errorMessage = "Please check your fields! some Fields are empty";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          return;
        }

        const response = await this["supplier/updateSupplierInformation"](
          this.supplierInfoCopy
        );

        if (response) {
          window.scrollTo(0, 0);
          this.successMessage = "Your changes have been saved";
          this.showMessage = true;
          if (this.currentRouteName !== "SupplierSetting") {
            this.$emit("change-step", "ShippingInfo");
          }
          setTimeout(() => {
            this.showMessage = false;
            this.successMessage = "";
          }, 3000);
          this.supplierInfoCopy = JSON.parse(JSON.stringify(this.supplierInfo));
        } else {
          window.scrollTo(0, 0);
          this.errorMessage = "Something went wrong";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
        }
      } else {
        window.scrollTo(0, 0);
        this.errorMessage =
          "No value changed. Please update your information's first.";
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
          this.errorMessage = "";
        }, 3000);
      }
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    DiscardChanges() {
      if (this.valueChanged) {
        this.supplierInfoCopy = JSON.parse(JSON.stringify(this.supplierInfo));
        this.forceRerender();
      }
    },
    async GetCountries() {
      try {
        const response = await CommonData.getCountries();
        this.countries = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async SetNewCountry(countryID) {
      try {
        const found = this.countries.find(
          (country) => country.id === countryID
        );
        this.supplierInfoCopy.collection_country_id = found.iso2;
        this.supplierInfoCopy.collection_country_name = found.name;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.general-information-section {
  .np-footer {
    justify-content: flex-start;
    flex-wrap: wrap;
    .noFee {
      color: #008060;
    }
  }
  .customize-label label {
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-size: 14px !important;
    color: #202223 !important;
  }
  .field-container {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }
  .general-info-select {
    label {
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      font-size: 14px !important;
      color: #202223 !important;
    }
  }
}
</style>
